import { ChakraProvider } from '@chakra-ui/react';
import { useState } from 'react';
import { AppContext } from './AppContext';
import { ColorModeSwitcher } from './components/ColorModeSwitcher';
// import { GlobalLoadingIndicator } from './components/GlobalLoadingIndicator';
import DefaultLayout from './layouts/Default';
import theme from './theme';

export const App = () => {
  const [token, setToken] = useState(null);
  // const [loading, setLoading] = useState(false);

  return (
    <ChakraProvider theme={theme}>
      <AppContext.Provider
        value={{ token, setToken /*, loading, setLoading */ }}
      >
        <ColorModeSwitcher style={{ position: 'fixed', top: 0, right: 0 }} />
        {/* <GlobalLoadingIndicator
          style={{ position: 'fixed', top: 0, right: 40 }}
        /> */}
        <DefaultLayout />
      </AppContext.Provider>
    </ChakraProvider>
  );
};
