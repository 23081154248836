import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  CircularProgress,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { AppContext } from '../AppContext';

const AUTH_URL = `${process.env.REACT_APP_API_URL}/v1/login`;

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { setToken } = useContext(AppContext);

  const login = (username, password) => {
    setLoading(true);
    fetch(AUTH_URL, {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setToken(data.access_token);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };

  return (
    <Flex
      minH={'66vh'}
      align={'flex-start'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      {loading ? (
        <CircularProgress
          isIndeterminate
          color="green.300"
          size={200}
          thickness={'2px'}
          margin={'auto'}
        />
      ) : (
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Sign in</Heading>
            {/* <Text fontSize={'lg'} color={'gray.600'}>
            to enjoy all of our cool <Link color={'blue.400'}>features</Link> ✌️
          </Text> */}
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={42}
          >
            <Stack spacing={4}>
              <FormControl id="username">
                <FormLabel>Account</FormLabel>
                <Input
                  type="text"
                  onChange={(evt) => setUsername(evt.target.value)}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  onChange={(evt) => setPassword(evt.target.value)}
                  onKeyUp={(evt) => {
                    if (evt.key === 'Enter') {
                      login(username, password);
                    }
                  }}
                />
              </FormControl>
              <Stack spacing={10}>
                {/* <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}
              >
                <Checkbox>Remember me</Checkbox>
                <Link color={'blue.400'}>Forgot password?</Link>
              </Stack> */}
                <Button
                  type="submit"
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  onClick={() => login(username, password)}
                >
                  Sign in
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      )}
    </Flex>
  );
};

export default Login;
