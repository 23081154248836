import { Box, Card, Link } from '@chakra-ui/react';
import { CheckCircleIcon, WarningTwoIcon } from '@chakra-ui/icons';

const color = (status: number) => (status === 1 ? 'green.500' : 'white');
const bgColor = (status: number) =>
  status === 1 ? undefined : status === -1 ? 'orange.400' : 'red.500';
const iconComp = (status: number) =>
  status === 1 ? CheckCircleIcon : WarningTwoIcon;

export const Service = (props: {
  name: string;
  url: string;
  rt: number;
  status: number;
}) => {
  const { name, url, rt, status } = props;
  const Icon = iconComp(status);
  return (
    <Card
      color={color(status)}
      backgroundColor={bgColor(status)}
      size="md"
      variant="elevated"
    >
      <Link href={url} target={'_blank'}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            {name} {status === 1 ? <small>({rt} ms)</small> : ''}
          </Box>
          <Box>
            <Icon color={color(status)} />
          </Box>
        </Box>
      </Link>
    </Card>
  );
};
