// Chakra imports
import { Box } from '@chakra-ui/react';
import Footer from '../components/Footer';
import InternalDashboard from '../views/Internal';
// import PublicDashboard from '../views/Public';
import Login from '../views/Login';
import { useContext } from 'react';
import { AppContext } from '../AppContext';

export default function Dashboard(/*props: { [x: string]: any }*/) {
  const { token } = useContext(AppContext);

  // const { isPublic } = props;
  return (
    <Box
      margin="auto"
      minHeight="100vh"
      height="100%"
      overflow="auto"
      position="relative"
      maxHeight="100%"
      w={{ base: '100%', xl: 'calc( 100% - 200px )' }}
      maxWidth={{ base: '75%', xl: 'calc( 75% - 200px )' }}
      transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
      transitionDuration=".2s, .2s, .35s"
      transitionProperty="top, bottom, width"
      transitionTimingFunction="linear, linear, ease"
    >
      <Box mx="auto" p={{ base: '20px', md: '30px' }} pe="20px" pt="50px">
        {/* {isPublic ? <PublicDashboard /> : <InternalDashboard />} */}
        {/* <PublicDashboard /> */}
        {token ? <InternalDashboard /> : <Login />}
      </Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  );
}
