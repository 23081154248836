import { extendTheme } from '@chakra-ui/react';
import { cardTheme } from '../components/Card';

const theme = extendTheme({
  components: {
    Card: cardTheme,
  },
  styles: {
    global: {
      'html, body': {
        fontSize: 'sm',
      },
    },
  },
});

export default theme;
