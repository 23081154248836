import { Box, Image, Text, Card } from '@chakra-ui/react';
import logo from '../assets/logo.png';
import moment from 'moment';
import { useEffect, useState } from 'react';

const bgColor = (mode: 'ok' | 'warn' | 'panic') => {
  switch (mode) {
    case 'ok':
      return 'green.500';
    case 'warn':
      return 'green.500';
    case 'panic':
      return 'red.500';
  }
};

const message = (mode: 'ok' | 'warn' | 'panic') => {
  switch (mode) {
    case 'ok':
      return 'All services operational';
    case 'warn':
      return "All services operational, except a few, but that's OK";
    case 'panic':
      return 'Houston, we have a problem';
  }
};

export const Header = (props: {
  checkDate: Date;
  mode: 'ok' | 'warn' | 'panic';
}) => {
  const { checkDate, mode } = props;

  const [, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        mb={10}
      >
        <Image src={logo} w={6} />
        <Text as="span" fontSize="3xl" ms="4px" color="gray.600">
          dicomLAB
        </Text>
      </Box>
      <Card color="white" backgroundColor={bgColor(mode)} mb={4}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box>{message(mode)}</Box>
          <Box>
            <small>Updated {moment(checkDate).fromNow()}</small>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
