export const httpRequest = (
  url: string,
  init?: RequestInit,
): Promise<any[] | number | null> => {
  return new Promise((resolve, reject) => {
    fetch(url, init)
      .then((response) => {
        if (response.status === 429) {
          return null;
        }
        if ([401].includes(response.status)) {
          return response.status;
        }
        if (response.status !== 200) {
          return [];
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
